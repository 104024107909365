<template>
    <v-card>
        <v-subheader v-if="loadingIngresso == false">Ingressos || EVENTO: {{evento.name}}</v-subheader>
        <v-subheader v-if="loadingIngresso == false">RC: {{evento.rc}}</v-subheader>

        <v-card-text v-if="loadingIngresso == false">
                <v-flex   v-for="item in ingressos" class="justify-center mx-auto mt-2" :key="item._id">
                    <v-card class="cards-padrao">
                        <v-divider></v-divider>

                        <v-col md="10" cols="10" class=" justify-center  text-center mx-auto">
                            <v-text-field 
                            type="text" label="Nome:" outlined
                            v-model="item.name" required>
                            </v-text-field>
                        </v-col>
                        
                        <v-divider></v-divider>
                        
                        <v-col class="card-text">
                            <v-row>
                                <v-col cols="12" class="col-md-6">
                                    <h2 class=" justify-center mx-auto mt-2"> Data Inicial:{{item.dataStart | formatDate }}</h2>
                                    <v-text-field 
                                    type="date"
                                    v-model="item.dataStart" required>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" class="col-md-6">
                                    <h2 class=" justify-center mx-auto mt-2"> Data Final:{{item.dataEnd | formatDate }}</h2>
                                    <v-text-field 
                                    type="date"
                                    v-model="item.dataEnd" required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-col>
                               <v-card xs8  v-for="ticket in item.ticket" :key="ticket._id">
                                    <v-row class=" justify-center mx-auto mt-2 mb-5">
                                        <v-col md="6" cols="10">
                                            <v-text-field 
                                            type="text" label="Nome:"
                                            v-model="ticket.name" required>
                                            </v-text-field>
                                       </v-col>
                                       <v-col md="3" cols="10">  
                                           <v-text-field 
                                            type="tel" label="Valor: " v-model="ticket.price"
                                             required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col md="3" cols="10">
                                            <v-text-field 
                                            type="number" label="Quantidade:"
                                            v-model="ticket.qnt" required>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn class=" mt-2" dark
                                @click="AddArea(item)">
                                    <v-icon
                                    icon:mdi-view-dashboard                               
                                    large
                                    >mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col class="mx-auto text-center"> 
                                <v-btn class=" mt-2 mr-2" @click="DeletTicket(item)" color="error">EXCLUIR</v-btn>
                                <v-btn color="primary" @click="PutTicket(item)" class="mt-2">SALVAR</v-btn>
                            </v-col>
                        </v-col>
                    </v-card>
                </v-flex>
                <!-- CRIAÇÂO DE INGRESSO-->
                <v-flex    class="justify-center mx-auto mt-2" >
                    <v-card class="cards-padrao" style="background: #c6c6c6">
                        <v-divider></v-divider>
                            
                        <v-col md="10" cols="10" class=" justify-center  text-center mx-auto">
                            <h2 class="mt-2 mb-5"> CRIAR INGRESSO </h2> 
                            <v-divider></v-divider>
                            <v-text-field 
                            type="text" v-model="NewIngresso.name"
                            label="Nome lote" outlined max-width="150"
                            required></v-text-field>
                        </v-col>
                        
                        
                        <v-col class="card-text">
                            <v-row class="justify-center mx-auto text-center">
                                <!-- Data ínicio-->
                                <v-col  class=" justify-center mx-auto text-center">
                                    <v-list-item-content>
                                    <v-icon>mdi-calendar-check-outline</v-icon>
                                    <v-text-field 
                                    type="date" class="box-input" v-model="NewIngresso.dataStart"
                                    label="Data Início" value="dataStart"
                                    required></v-text-field>
                                    </v-list-item-content>
                                </v-col>
                                <!-- Data fim-->
                                <v-col  class="justify-center mx-auto text-center">
                                    <v-list-item-content>
                                    <v-icon>mdi-calendar-check-outline</v-icon>
                                    <v-text-field v-model="NewIngresso.dataEnd"
                                    type="date" class="box-input"
                                    label="Data Final"
                                    required value="dataEnd"
                                    ></v-text-field>
                                    </v-list-item-content>
                                </v-col>                           
                            </v-row>
                            <template>
                            <div v-for="(ticket ,idx) in NewIngresso.ticket" :key="idx">
                                <h5 class="mt-2">Área: {{idx + 1}}</h5>
                                <v-list-item-group class="area-evento">
                                    <v-col class="justify-left mx-auto text-center">
                                    <!-- Nome Área-->
                                    <v-text-field
                                    type="text" class="box-text" name="name[]" 
                                    label="Nome área" outlined v-model="NewIngresso.ticket[idx].name"
                                    required
                                    ></v-text-field>
                                    <v-col class="justify-center mx-auto text-center">
                                    <v-row class="mx-auto text-center">
                                    <!-- Quantidade do Lote -->
                                     <v-list-item-content class=" justify-center mx-auto text-center">
                                           <v-icon>mdi-ticket-confirmation</v-icon>
                                            <v-text-field 
                                            type="number" class="box-input box-2" v-model="NewIngresso.ticket[idx].qnt"
                                            outlined  name="qtn[]" 
                                            label="Quantidade"
                                            required></v-text-field>
                                        </v-list-item-content>
                                        <!-- Valor Lote-->
                                        <v-list-item-content class=" justify-center mx-auto text-center ml-3">
                                            <v-icon>mdi-currency-usd</v-icon>
                                            <v-text-field  v-model="NewIngresso.ticket[idx].price"
                                            type="number" class="box-input"
                                            outlined  name="price[]" 
                                            label="Valor" 
                                            required 
                                            ></v-text-field>
                                        </v-list-item-content>
                                        </v-row>
                                        <v-row  class=" float-right">
                                            <v-btn dark   
                                            style="cursor:pointer" color="error" 
                                            @click="DeleteArea(idx)">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-row>

                                    </v-col>
                                    </v-col>
                                    
                                </v-list-item-group>
                            </div>
                            </template>
                           <v-col class="justify-left mx-auto text-center">
                                <v-btn class=" mt-2" dark
                                @click="AddAreaNewIngresso()">
                                    <v-icon
                                    icon:mdi-view-dashboard
                                    large
                                    >mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col class="justify-left mx-auto text-center">
                                <v-btn
                                color="primary" class="mt-2"
                                @click="CreateTicket()"> <!-- e6 = 4 -->
                                Adicionar
                                </v-btn>
                            </v-col>
                           
                        </v-col>
                    </v-card>
                </v-flex>
        </v-card-text>
            <!-- LOADING....-->
        <v-card-text>
                <v-container style="height: 400px;" v-if="loadingIngresso == true">
                    <v-row 
                    class="fill-height"
                    align-content="center"
                    justify="center"
                        >
                        <v-col class="justify-center mx-auto text-center"  cols="6">
                            <img  class="mb-3" src="@/assets/logo.png">
                            <h5> CARREGANDO...</h5>
                            <v-progress-linear
                            color="deep-purple accent-4"
                            indeterminate
                            rounded
                            height="8"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-container>
        </v-card-text>
    </v-card>  
</template>
<script>
import { showError, showSucess} from '@/global'
import { app, http } from '@/server'
import { replaceUrl } from '@/functions/helpers'

import moment from 'moment';

export default{
    data(){
        return{
            tab: 0,
            loadingIngressos: false,
            ingresso:{},
            NewIngresso:{
                eventId: this.evento._id,
                dataStart:null,
                dataEnd:null,
                name: null,
                description: null,
                ticket:[{ name:'', price:'', qnt:''}]
            },
            area:{},
        }
    },
    props: {
    visible: Boolean,
    evento: Object,
    loadingIngresso: Boolean,
    ingressos: Array
    },
    computed: {
        getEvento:{
            get(){
                 
                return this.evento, this.ingressos, this.startIngresso

            }
        },
        show: {
            get () {
                this.GetTicket()
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        }
    },
    filters: {
        formatDate:function(value){
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        }
    },
    methods:{
        CreateTicket(){
            app.createTicket.data = this.NewIngresso
            http.request(app.createTicket)

            .then((res) => {
                if (res.status == 200){
                this.loadingIngressos = false
                showSucess(res);
                this.Restart()
                this.GetTicket()
                }
            })
            .catch(this.loadingIngressos = true)
            .catch((err)=>{
                if (err != 200){    
                this.loadingIngressos = false
                showError(err)
                }
            })
        },
        Restart(){
            this.NewIngresso.dataStart = null
            this.NewIngresso.dataEnd = null
           this.NewIngresso.name = null
           this.NewIngresso.ticket=[{ name:'', price:'', qtn:''}]
            this.loadingIngresso = false

        },
        GetTicket(){
            const url = app.getEditTickets.url
            app.getEditTickets.url = replaceUrl(url, '{event_id}', this.evento._id)

            http.request(app.getEditTickets)
             .then(res => {
                app.getEditTickets.url = '/promoter/ticket/{event_id}'

                this.startIngresso = false
                this.ingressos = res.data
            })
            .catch(this.startIngresso = true)
            .catch((err)=>{
                if (err != 200){   
                    app.getEditTickets.url = '/promoter/ticket/{event_id}'

                    this.startIngresso = false
                }
                })

        },
        PutTicket(ingresso){
            this.ingresso = { ...ingresso}
            const url = app.putEditTickets.url
            app.putEditTickets.url = replaceUrl(url, '{ingresso_id}', this.ingresso._id)
            app.putEditTickets.data = this.ingresso
            http.request(app.putEditTickets)
             .then(res => {
                if (res.status == 200){
                    app.putEditTickets.url = '/promoter/ticket/{ingresso_id}'

                    this.loadingIngressos = false
                    showSucess(res)
                    this.GetTicket()
                }
            })
            .catch(this.loadingIngressos = true)
            .catch((err)=>{
                if (err != 200){    
                    app.putEditTickets.url = '/promoter/ticket/{ingresso_id}'

                    this.loadingIngressos = false
                }
                })

        },
        DeletTicket(ingresso){
            this.ingresso = { ...ingresso}
            const url = app.deleteTickets.url
            app.deleteTickets.url = replaceUrl(url, '{ingresso_id}', this.ingresso._id)
            http.request(app.deleteTickets)
            .then(res => {
                if (res.status == 200){
                    app.deleteTickets.url = '/promoter/ticket/{ingresso_id}'

                    this.loadingIngressos = false
                    this.GetTicket()
                }
            })
            .catch(this.loadingIngressos = true)
            .catch((err)=>{
                if (err != 200){    
                    app.deleteTickets.url = '/promoter/ticket/{ingresso_id}'

                    this.loadingIngressos = false
                }
                })

        },
        AddArea(area){
        this.ingresso = { ...area}
        this.ingresso.ticket.push({name:'', price:'', qtn:''})
        },
        AddAreaNewIngresso(){
        this.NewIngresso.ticket.push({name:'', price:'', qtn:''})
        },
        DeleteArea(idx){
        if(idx > 0)this.NewIngresso.ticket.splice(idx,1)
        },
    }
}
</script>