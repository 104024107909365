<template>
<v-dialog v-model="show" max-width="500px">
  <v-card>
    <v-stepper
      style="background: #120222"
      v-model="e6"
      vertical
      dark
      >
      <div>
      
      <h2 class="justify-center mx-auto text-center">{{evento.name}}</h2>
      </div>
      <!--Começo primeiro Point-->
      <v-stepper-step
          :complete="e6 > 1"
          step="1" @click="e6 = 1"
          > 
        Criar evento
        <small>Selecione uma imagem e descrição</small>
      </v-stepper-step>
      <v-stepper-content step="1">
      
                <v-row class="justify-center mx-auto text-center">
                  <v-col class="justify-center mx-auto text-center">
                      <div>
                        <div class="imagem2-upload text-center mx-auto">
                         <div>
                          <input type="file" 
                          @change="pickFile1"
                          name="file1"
                          ref="fileInput" required
                          class="input-file">
                          <button>
                            <v-icon
                            class="mt-15"
                            dense color="red" @click="selectImage"
                            v-if="loading == false & previewImage == null & error == true"
                            >mdi-cloud-alert</v-icon>
                            <v-icon
                            class="mt-15"
                            @click="selectImage"
                            v-if="previewImage == null & loading == false & error == false" dark>mdi-cloud-upload</v-icon>
                            <v-progress-circular
                            indeterminate
                            color="purple"  class="mt-15"
                            v-if=" loading == true & previewImage == null & error == false"
                            @click="selectImage"
                            ></v-progress-circular>
                          </button>
                        </div>
                        <div
                        class=" justify-center imagePreviewWrapper"
                        :style="{ 'background-image': `url(${previewImage})` }">
                        </div>
                        </div>
                      </div>
                  </v-col>
                </v-row> 
                <v-row>
                  <v-col class="justify-center mx-auto text-center">
                    <div>
                      <div class=" text-center mx-auto">
                        <v-row class=" justify-center mx-auto text-center">
                          <h5 class="mr-2 mt-2">Nome do evento: </h5>
                          <v-text-field 
                            type="text" 
                            outlined :rules="[v => !!v || 'Name é obrigatório']"
                            clearable  dense
                            v-model="evento.name" 
                            required></v-text-field>
                        </v-row>
                      </div>
                    </div>
                </v-col>
                </v-row>  
                <v-row>
                  <v-col>
                       <div class=" text-center mx-5 mt-5">
                          <v-textarea dark 
                            height="50px"
                            label="Descrição..." 
                            v-model="evento.description"
                            required></v-textarea>       
                      </div>  
                  </v-col>
                </v-row>            
                <v-btn
                  color="primary"
                  @click=" e6 = 2"
                >
                  Continue
                </v-btn>
                <v-btn text
                @click="$emit('close')">
                  Cancel
                </v-btn>
     </v-stepper-content>
     <!--Final primeiro Point-->

     <!--Começo segundo Point-->
      <v-stepper-step
      :complete="e6 > 2"
      @click="e6 = 2"
       step="2"
      >
      Configurações evento
      <small>Selecione as configurações do evento</small>
      </v-stepper-step>
      <v-stepper-content step="2"> 
        <v-row class="justify-center mx-auto text-center" v-if="loadingEvento == false">
        <!-- CATEGORIA E GENERO-->
        <v-row class="justify-center mx-auto text-center">
          <v-col>
              <div>
                <div class=" text-center mx-auto">
                  <v-row class="box-input justify-center mx-auto text-center">
                      <div>
                        <div>
                          <v-select
                              v-model="evento.category"
                              :items="categorias"
                              item-text="name" item-value="_id"
                              @change="getGenreList()"
                              attach
                              chips
                              label="Categorias"                              
                          ></v-select>
                        </div>
                      </div>
                  </v-row>
                </div>
              </div>
          </v-col>
          <v-col>
            <div>
              <div class=" text-center mx-auto">
                <v-row class="box-input justify-center mx-auto text-center">
                    <div>
                      <div>
                        <v-select
                            v-model="evento.genre"
                              :items="generos"
                              item-text="name"
                              item-value="_id" 
                              attach
                              chips
                              label="Gênero"
                          ></v-select>
                      </div>
                    </div>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>  
        <!-- DATA E HORA--> 
        <v-row class="justify-center mx-auto text-center">
          <v-col class="justify-center mx-auto text-center">
              <div class="justify-center mx-auto text-center">
                <v-row class="box-input  justify-center mx-auto text-center">
                  
                        
                  <h5>Horário</h5>
                  <v-dialog
                    ref="dialog1"
                    v-model="modal2"
                    :return-value.sync="time"
                    persistent
                    width="300px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="time" :change="MakeTime()"
                      label="Escolha o horário"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                    v-if="modal2"
                    format="24hr"
                    v-model="time"
                    full-width
                    color="purple"
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    color="purple"
                    @click="modal2 = false"
                    >
                    Cancel
                    </v-btn>
                    <v-btn
                    text
                    color="purple"
                    @click="$refs.dialog1.save(time)"
                    >
                    OK
                    </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-row>        
              </div>            
          </v-col>
          <v-col>   
            <div class=" text-center mx-auto">
              <v-row  class="box-input justify-center mx-auto text-center">
                  <h5>Data</h5>
                  <v-dialog
                  ref="dialog"
                   color="purple"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                 >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date" :change="MakeDate()"
                    label="Data"
                    prepend-icon="mdi-calendar"
                    readonly 
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  scrollable
                  color="purple"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="purple"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="purple"
                    @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
                 </v-dialog>
              </v-row>     
            </div>
          </v-col>   
        </v-row>  
        <!-- IDADE E LOCAL-->
        <v-row class="justify-center mx-auto text-center">
          <v-col>
            <v-row class="box-input  justify-center mx-auto text-center">
              <h5>Local:</h5>
              <v-text-field 
              type="text" 
              outlined
              dense
              v-model="evento.local" 
              required>
              </v-text-field>
            </v-row>
          </v-col>
          <v-col>
            <v-row class="box-input  justify-center mx-auto text-center">
              <h5>Idade mínima :  </h5>
              <v-text-field 
              type="number" 
              outlined
              clearable  dense
              v-model="evento.age" 
              required>
              </v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <!-- ESTADO E CIDADE -->
          <v-row class="justify-center mx-auto text-center">
            <v-col class="justify-center mx-auto text-center">
              <div class="justify-center mx-auto text-center">
                <v-row class="box-input  justify-center mx-auto text-center">

                  <h5 >Estado:</h5>
                  <v-select
                  @change="getCidades()"
                  outlined 
                  :items="estados"
                  item-text="nome"
                  item-value="sigla"
                  v-model="evento.city.state"
                  dense
                  required></v-select>

                </v-row>
              </div>
           </v-col>
           <v-col>
              <v-row class="box-input justify-center mx-auto text-center">
                <h5>Cidade:</h5>
                <v-select
                outlined
                :items="municipios"
                item-text="nome"
                iteme-value="nome"
                v-model="evento.city.name"
                dense
                required>
                </v-select>
             </v-row>
            </v-col>
          </v-row>
        </v-row>
          <v-container style="height: 400px;" v-if="loadingEvento == true">
            <v-row 
                class="fill-height"
                align-content="center"
              justify="center"
              >
              <v-col class="justify-center mx-auto text-center"  cols="6">
                <img  class="mb-3" src="@/assets/logo.png">
                <h5> CRIANDO SUA NIGHT...</h5>
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="8"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
          <v-col class="mt-10">
          <v-btn
          color="primary"
          @click="CreatedEvento()"
          ><!-- e6 = 3 -->
            Salvar
          </v-btn>
          <v-btn text
          @click="$emit('close'), $emit('atualizar')">
            Cancel
          </v-btn>
          </v-col>
      </v-stepper-content>

      <!--Final segundo Point-->

      <!--Começo terceiro Point-->
      <v-stepper-step
         :complete="e6 > 3"
          
          step="3"
      >
        Ingressos
       <small>Configurações de lotes e datas</small>
      </v-stepper-step>
      <v-stepper-content step="3">
       <Criaingresso class="mt-5" @close="close = $emit('close')" @next =" e6 = 4" :IdEvento="IdEventoCriado" @atualizar="$emit('atualizar')"/>
        </v-stepper-content>
      <!--Final terceiro Point-->

      <!--Começo quarto Point-->
      <v-stepper-step 
      step="4"
      >
        Bedidas
         <small>Quantias e Valores</small>
      </v-stepper-step>
     <v-stepper-content step="4">
      <CriaBebida  @close="close = $emit('close')" @next =" e6 = 1" @more ="e6 =4" :IdEvento="IdEventoCriado" @atualizar="$emit('atualizar')"/>
      </v-stepper-content>
      <!--Final quarto Point-->
    </v-stepper>
  </v-card>
</v-dialog>
</template>

<script>
import Criaingresso from '@/components/Evento/CriaIngresso.vue'
import CriaBebida from '@/components/Evento/Bebida.vue'
import {showError, showSucess} from '@/global'
import service from "@/config/services"
import axios from 'axios'
import { app, http } from '@/server'
import { replaceUrl } from '@/functions/helpers'

export default {
  components:{Criaingresso, CriaBebida},
  props: {
    visible: Boolean,
    },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
   
  },
  data(){
    return{
      IdEventoCriado: null,
      date:'',
      loadingEvento: false,
      modal: false,
      error: false,
      estados: null,
      loading: false,
      municipios: null,
      selection: {},
      close: false,
      UF: "",
      e6: 1,
      modal2: false,
      time: null,
      photo1:null,
      categorias:null,
      generos:null,
      previewImage: null,
      evento:
        {
          name:"",
          date:{
            date: "",
            time: ""
          },
          age:"",
          local:"",
          photo:{
            location: null,
            key:null,
          },
          city:{
            name: "",
            state:"",
          },
          description: "",
          category: "",
          genre: "",
        },
      
    } 
  },
  created() {
    this.getEstados();
    this.getCategoryList();
  },
  methods: {
   selectImage () {
     this.$refs.fileInput.click()
    },

    pickFile1() {
      this.photo1 = this.$refs.fileInput.files[0];
      const formData = new FormData();
      formData.append('file', this.photo1);
      service.uploadImage.data = formData
      http.request(service.uploadImage).then((res) => { 
        this.evento.photo.key = res.data.key
        this.evento.photo.location =res.data.location
        console.log(this.evento)
        let input = this.$refs.fileInput
        let file = input.files
        if (file && file[0]) {
          let reader = new FileReader
          reader.onload = e => {
            this.previewImage = e.target.result
          }
          reader.readAsDataURL(file[0])
          this.$emit('input', file[0])
        }
      }).catch(this.loading = true, this.previewImage = null, this.error = false)
      .catch((err)=>{
        if (err != 200){    
          this.loading = false
          this.error = true
          showError(err)
        }
      })
    },
       
    clear(){ this.selection = null },
        
    /*printDate: function () {
      return new Date().toLocaleDateString();
            
    },*/

    getEstados() {
      axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((res) => {
          this.estados = res.data;
        })
       .catch((error) => {
          console.log(error);
        });
    },

    getCidades() {
      axios
        .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.evento.city.state}/municipios`)
        .then((res) => {
          this.municipios = res.data;
        })
        .catch()
    },

    getCategoryList(){
      http.request(service.getCategoryList)
        .then((res) => {
          this.categorias = res.data;
        })
      .catch()
    },

    getGenreList(){
      const url = service.getGenreList.url
      service.getGenreList.url = replaceUrl(url, '{category_id}', this.evento.category)

      http.request(service.getGenreList)
        .then((res) => {
          service.getGenreList.url = '/genre?categoryId={category_id}'

          this.generos = res.data;
        })
        .catch()
    },
    MakeDate(){
      this.evento.date.date = this.date
    },
    MakeTime(){
      this.evento.date.time = this.time
    },
    CreatedEvento(){
      app.createEvento.data = this.evento
      http.request(app.createEvento)
      .then(res =>{
          if(res.status === 200){
            this.RestartEvento()
            this.loadingEvento = false
            this.IdEventoCriado = res.data.id
            console.log(this.IdEventoCriado)
            showSucess(res)
          }
      })
      .catch(this.loadingEvento = true)
      .catch((err)=>{
        if (err != 200){    
          this.loadingEvento = false
          showError(err)
        }
      })

    },
    RestartEvento(){
      this.evento.name=null
      this.evento.date.date=null
      this.evento.date.time=null
      this.evento.age=null
      this.evento.local=null
      this.previewImage = null
      this.evento.photo = {}
      this.evento.city.name = null
      this.evento.city.state = null
      this.evento.description= null
      this.evento.category = null,
      this.evento.genre = null
      this.e6 = 3

    }
  },
   mounted: function () {
     // this.date = this.printDate();
      // this.evento.registerDate =this.date;
   },
}
</script>   
<style scoped>
v-textarea textarea{
  height: 40px !important;
  margin: 0;
}
#myFileInput{
 position:absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.imagem2-upload{
  border-radius: 22px;
  width: 150px;
  height: 150px;
  border: 2px solid #D356F3;
  color: #D356F3;
  line-height: 70px;
  cursor: pointer;
  
}
.imagePreviewWrapper {
   border-radius: 20px;
   line-height: 80px;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    margin-top: -70px;
}
.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}
.box-input{
  width: 175px;
  padding: 5px;
  color:#fff;
  margin: 5px;
}

@media (max-width: 650px) {
  .box-input{
   width:200px;
  }
}
</style>