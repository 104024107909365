<template>
  <v-app>
    <Sidebar/>
    <v-flex class="alltheitens" v-if="loadingEventos == false">
      <v-row >
        <v-flex v-for="item in eventos" class="justify-center mx-auto" :key="item.id" xs8 sm5 md4 lg4>
                <v-card shaped class="cards-padrao ">
                  <div class="mb-5 card-img">
                    <img  v-if="item.photo != null" :src="`${item.photo.location}`"/>
                    <v-icon
                    color="#000"  x-large class="loade"
                    v-else
                    >mdi-cloud-upload</v-icon>
                  </div>
                  <v-divider></v-divider>
                  <div class="card-text mb-2 ">
                    <h4 class=" justify-center mx-auto">{{item.name}}</h4>
                    <h6 class=" justify-center mx-auto mt-2">RC: {{item.rc}}</h6>
                  </div>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="orange"  @click="loadEvento(item), editandoEvento=true">Editar</v-btn>
                  </v-card-actions>
                </v-card>
        </v-flex>
      </v-row>
       <v-row>
        <v-card-actions class="criar-evento justify-end">
              <v-btn
              rounded x-large
              @click.stop="criaevento=true">
                <v-icon
                  icon:mdi-view-dashboard
                  color="purple"
                  large
                >mdi-plus</v-icon><h3> criar evento </h3>
              </v-btn>
        </v-card-actions>            
      </v-row>
      <EditarEvento :visible="editandoEvento" @close="editandoEvento=false" :evento="editEvento" @atualizar="GetEventos()"/>
      <Criarevento :visible="criaevento" @close="criaevento=false" @atualizar="GetEventos()"/>
    </v-flex>

    <!-- Loading-->
     <v-flex class="justify-center mx-auto" xs8 sm8 md4 lg4>

      <v-row class=" justify-center mx-auto">
          <v-progress-circular v-if="loadingEventos == true"
          :size="150" 
          :width="10" style="margin-top:150px" 
          color="purple" class="d-flex justify-center"
          indeterminate
          ></v-progress-circular>
      </v-row>
     </v-flex>
  </v-app>
</template>
<script>
import Criarevento from '@/components/Evento/CriaEvento.vue'
import Sidebar from '@/components/Sidebar/Sidebar.vue'
import { showError} from '@/global'
import { app, http } from '@/server'
import EditarEvento from '@/components/EditarEvento/EditarEvento.vue'
import { mapState } from "vuex"
export default {
  name: 'Eventos',
  computed: mapState([ 'user']),
  components: {
    Criarevento,
    Sidebar,
    EditarEvento
  },
  data(){
    return{
      editandoEvento: false,
      criaevento: false,
      loadingEventos: false,
      eventos: [],
      editEvento:{},
      dialog: false,
      e6:1,
      close: false,
    }
  },
  mounted() {
    document.title = 'Evento'
    this.GetEventos()
  },
  methods:{
    GetEventos(){
      http.request(app.getEventos)
      .then(res => {
        this.loadingEventos = false
        this.eventos = res.data
      })
      .catch(this.loadingEventos = true)
      .catch((err)=>{
          if (err != 200){    
            this.loadingEventos = false
            showError()
          }
        })
    },
    loadEvento(editEvento){
      this.editEvento = {...editEvento}
    }
  },
  created() {
  this.GetEventos()

  }
}
</script>
<style scoped>
.cards-padrao{
  text-align: center;
  width: 250px !important;
  height: 360px !important;
  padding: 10px;
  margin:5px;
}
.card-img{
  height: 210px !important;
  margin: auto;
  width: 200px;
}
.card-img img{
    height: 100%;
    width: 100%;
    border-radius: 25px;
}


.card-text{
    max-width:200px !important;
    height:50px !important;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}
.loade{
 margin-top: 90px;
}
</style>