<template>
  <div>
    <v-container style="height: 350px;" v-if="loadingBebida == false">
      <!-- Titulo -->
      <v-row>
        <h5 class="justify-center mx-auto text-center mb-5"> VENDA DE BEBIDAS</h5>
              
      </v-row>

      <hr>

      <template>
      <!-- Criação 1° BEBIDA-->
        <v-row class="justify-center mx-auto text-center mt-2">
          <v-col class="justify-center mx-auto text-center">
            <div class=" text-center mx-auto">
              <v-row class="justify-center mx-auto text-center mt-2">
                <div div class="imagem2-upload">
                  <div>
                    <input type="file" 
                    @change="pickFile1"
                    name="file1"
                    ref="fileInput"
                    class="input-file">
                    <button>
                      <!-- icone de erro-->
                      <v-icon
                      dense color="red" @click="selectImage1"
                      v-if="loading1 == false & previewImage == null & error == true"
                      >mdi-cloud-alert</v-icon>
                      <!-- icone de nuvem-->
                      <v-icon
                      @click="selectImage1" 
                      v-if="previewImage == null & loading1 == false & error == false" 
                      dark
                      >mdi-cloud-upload</v-icon>
                      <!-- carregando-->
                      <v-progress-circular
                      indeterminate
                      color="purple"
                      v-if=" loading1 == true & previewImage == null"
                      @click="selectImage1"
                      ></v-progress-circular>
                    </button>
                  </div>
                  <div
                  class="imagePreviewWrapperDrink"
                  :style="{ 'background-image': `url(${previewImage})` }">
                  </div>
                </div>

                <!-- NOME, QUANTIDADE, Tipo E PREÇO -->
                <v-row class="mt-2">
                  <v-row class="justify-center mx-auto text-center">
                    <v-col>
                      <v-text-field 
                      type="text" 
                      label="Nome bebida" 
                      v-model="Bebida.name"
                      required></v-text-field>
                    </v-col>
                    <v-col class="col-md-6" cols="12"> 
                    <v-select
                      label="Tipo" :items="tipos"
                      v-model="Bebida.type" 
                      required>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="justify-center mx-auto text-center mb-2">
                    <v-col class="justify-center mx-auto text-center col-md-6" cols="6">
                      <v-text-field 
                      type="number" 
                      outlined dense
                      label="Quantidade" 
                      v-model="Bebida.qnt" 
                      required>
                      </v-text-field>
                    </v-col>
                    <v-col class="justify-center mx-auto text-center col-md-6" cols="6">
                      <v-text-field 
                      type="number" 
                      outlined dense
                      label="Preço"
                      v-model="Bebida.price"  
                      required>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-row>
            </v-row>
            </div>
          </v-col>        
        </v-row>
      <!-- Final 1° BEBIDA-->
      </template>
      <hr>
    </v-container>
    <v-container style="height: 400px;" v-if="loadingBebida == true">
        <v-row 
                class="fill-height"
                align-content="center"
              justify="center"
              >
              <v-col class="justify-center mx-auto text-center"  cols="6">
                <img  class="mb-3" src="@/assets/logo.png">
                <h5> CRIANDO BEBIDA...</h5>
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="8"
                ></v-progress-linear>
              </v-col>
            </v-row>
    </v-container>
    <!-- Botões -->
    <div class="justify-center mx-auto text-center botons">  
      <v-btn
      color="primary" class="mt-3"
      @click="$emit('next'), $emit('close'), $emit('atualizar') "
      > <!--"$emit('next'), $emit('close')" -->
        FECHAR
      </v-btn>

      <v-btn text class="mt-3"
      @click="$emit('close'), $emit('atualizar')">
        Cancel 
      </v-btn>

      <v-btn
      color="dark" class="mt-3"
      @click="CriaBebida()"> <!-- e6 = 4 -->
        Adicionar
      </v-btn>

    </div>
  </div>
</template>
<script>
import service from "@/config/services"
import { app, http } from '@/server'
import {showError, showSucess} from '@/global'
export default{
  props: ['IdEvento'],

  data(){
    return{
      tipos:['DRINK', 'FOOD'],
      e6: 1,
      loadingBebida: false,
      loading1: false,
      previewImage: null,
      error: false,
      photo1: null,
      Bebida:{
        localId: null,
        name: null,
        price: null,
        qnt: null,
        type: {
          enum: "DRINK"
        },
        photo: {
          location: null,
          key: null,
        },
        description: null
      },
    }
  },
  methods: {
    selectImage1 () {
     this.$refs.fileInput.click()
    },
    pickFile1() {
      this.photo1 = this.$refs.fileInput.files[0];
      const formData = new FormData();
      formData.append('file', this.photo1);
      service.uploadImage.data = formData
      http.request(service.uploadImage).then((res) => { 
        this.Bebida.photo.key = res.data.key
        this.Bebida.photo.location =res.data.location
        let input = this.$refs.fileInput
        let file = input.files
        if (file && file[0]) {
        let reader = new FileReader
          reader.onload = e => {
            this.previewImage = e.target.result
          }
          reader.readAsDataURL(file[0])
          this.$emit('input', file[0])
          }
      }) .catch(this.loading1 = true, this.previewImage = null, this.error = false)
      .catch((err)=>{
        if (err != 200){    
          this.loading1 = false
          this.error = true
          showError(err)
        }
      })
    },
    CriaBebida(){
      this.Bebida.localId = this.IdEvento
      app.createBebida.data = this.Bebida
      http.request(app.createBebida)
      .then(res =>{
        if(res.status == 200){
          this.loadingBebida = false
          this.previewImage = null
          this.error = false
          this.loading1 = false
          this.Reset()
          showSucess(res)
        }
      })
      .catch(this.loadingBebida = true)
      .catch((err)=>{
        if (err != 200){    
          this.loadingBebida = false
          showError(err)
        }
      })

    },
    Reset(){
      this.Bebida.name = null
      this.Bebida.qnt = null
      this.Bebida.price = null
      this.Bebida.type = null

    }
  }
}
</script>
<style scoped>
.box-input{
  max-width:110px !important;
  padding: 5px;
  color:#fff;
}

.box3-input{
  max-width:150px !important;
  padding: 5px;
  color:#fff;
  margin-top: 30px;
  margin-right: -10px
}
.imagem2-upload{
  border-radius: 22px;
  width: 90px;
  height: 90px;
  border: 2px solid #D356F3;
  color: #D356F3;
  line-height: 90px;
  cursor: pointer;
  
}
.imagePreviewWrapperDrink{
   border-radius: 20px;
   line-height: 90px;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    margin-top: -90px;
    margin-left: 5px;
}
.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 10px;
    height: 200px;
    position: absolute;
    cursor: pointer;
}
@media (max-width: 600px) {
  .botons{
    margin-top: 60px;
  }
}
</style>