<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card tile >
          <v-toolbar max-height="80px"      
            dark
            color="purple"
          >
            <v-btn
              icon
              dark
              @click="$emit('close'), $emit('atualizar')"
            > <!-- $emit('close')-->
              <v-icon>mdi-close</v-icon> 
            </v-btn>
            <v-tabs v-model="tab"
            background-color="purple"
            center-active class="mt-4"
            dark
            >
                <v-tab>Evento</v-tab>
                <v-tab @click="GetTicket()"> Ingressos</v-tab>
                <v-tab @click="GetProduct()">Produtos</v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-tabs-items v-model="tab">

            <v-tab-item href="#tab-1">
                <v-card-text>
                    <v-subheader>
                        EVENTO: {{evento.name}}
                    </v-subheader>
                    <v-subheader>RC: {{evento.rc}}</v-subheader>

                    <v-form 
                        ref="form"
                        lazy-validation                  
                        class="justify-center mx-auto text-center">
                        <v-row class="justify-center mx-auto text-center">
                            <v-col>
                            <div class="card-img">
                                <v-progress-circular
                                indeterminate
                                color="purple" class="loade"
                                v-if=" loading == true && error == false"
                                
                                ></v-progress-circular>
                                <v-icon v-if=" loading == false && error == true"
                                 color="red" @click="selectImage()" x-large class="loade"
                                >mdi-cloud-alert</v-icon>

                                <v-icon
                                class="loade" color="#000"  x-large
                                @click="selectImage()" v-if=" loading == false && error == false && evento.photo == undefined || null"
                                >mdi-cloud-upload</v-icon>

                                <img  v-if=" loading == false && error == false && evento.photo != null" :src="`${evento.photo.location}`">
                            </div>    
                            <v-btn  @click="selectImage()" color="dark mt-2"> UPLOAD
                                <input type="file" 
                                class="input-file"
                                @change="pickFile1"
                                name="file1"
                                ref="fileInput"
                                >
                            </v-btn>     
                            </v-col>              
                        </v-row >
                            <div class="text-center mt-5">   
                                <v-text-field 
                                    class="uso-mascara" 
                                    type="text"  v-model="evento.name"
                                    label="Nome" 
                                    required>
                                </v-text-field>
                                 <v-text-field 
                                    class=" uso-mascara" 
                                    type="text" 
                                    label="Descrição" v-model="evento.description" 
                                    required>
                                </v-text-field>
                                <v-text-field 
                                    class=" uso-mascara" readonly
                                    type="text" 
                                    label="Cidade" v-model="evento.city" 
                                    >
                                </v-text-field>
                                <v-row class="uso-mascara mb-5 justify-center mx-auto text-center">
                                    <v-col class="col-8 col-sm-6 mt-6"><v-text-field 
                                    type="number" 
                                    class="input-multiple"
                                    label="Idade"
                                    v-model="evento.age" required>
                                    </v-text-field>
                                    </v-col>
                                    <v-col class="col-8 col-sm-6 ">
                                         <h5 class=" justify-center mx-auto"> Data:{{evento.date.date | formatDate }}</h5>
                                        <v-text-field 
                                        type="date" 
                                        class="input-multiple"
                                        v-model="evento.date.date" required>
                                        </v-text-field>

                                    </v-col>
                                </v-row>
                            </div>       
                    </v-form>
                </v-card-text>
                <v-row class="justify-center mx-auto text-center mb-5 mt-2">
                    <v-btn
                    depressed
                    color="error"
                    @click=" removeEvent()"
                    > <!-- removeEvent()-->
                      EXCLUIR
                    </v-btn>
                    <v-btn
                    depressed
                    color="primary ml-2"
                    @click=" PutEvent(evento)"
                    > <!-- removeEvent()-->
                      Salvar
                    </v-btn>
                </v-row>
            </v-tab-item>
            <v-tab-item href="#tab-2">
                <EditarIngresso :evento="evento" :ingressos="ingressos" :loadingIngresso="startIngresso"/>
            </v-tab-item>
            <v-tab-item href="#tab-3">
                <EditarBebida :evento="evento" :bebidas="produtos" :loadingBebida="loadingProduct"/>

            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-dialog>
</template>
<script>
import moment from 'moment';
import { showError, showSucess} from '@/global'
import service from "@/config/services"
import { app, http } from '@/server'
import { replaceUrl } from '@/functions/helpers'
import EditarIngresso from '@/components/EditarEvento/EditarIngresso.vue'
import EditarBebida from '@/components/EditarEvento/EditarBebida.vue'
export default{
    data(){
        return{
            startIngresso: false,
            loadingProduct: false,
            tab: 0,
            ingressos: [],
            municipios: [],
            error: false,
            event:{},
            photo1: null,
            loading: false,
            produtos: [],
        }
    },
    components:{
        EditarIngresso,
        EditarBebida
    },
    props: {
    visible: Boolean,
    evento: Object
    },
    filters: {
        formatDate:function(value){
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        }
    },
    computed: {
        getEvento:{
            get(){
                return this.evento

            }
        },
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        }
    },
    methods:{
        selectImage(){
        this.$refs.fileInput.click()
        },
        pickFile1() {
            this.photo1 = this.$refs.fileInput.files[0];
            const formData = new FormData();
            formData.append('file', this.photo1);
            service.uploadImage.data = formData
            http.request(service.uploadImage).then((res) => { 
                this.evento.photo.key = res.data.key
                this.evento.photo.location =res.data.location
                let input = this.$refs.fileInput
                let file = input.files
                this.loading = false
                if (file && file[0]) {
                    let reader = new FileReader
                    reader.onload = e => {
                        this.previewImage = e.target.result
                    }
                    reader.readAsDataURL(file[0])
                    this.$emit('input', file[0])
                    }
            }) .catch(this.loading = true)
            .catch((err)=>{
                if (err != 200){    
                this.loading = false
                this.error = true
                showError(err)
                }
            })
        },
        removeEvent(){
            const url = app.deleteEvento.url
            app.deleteEvento.url = replaceUrl(url, '{event_id}', this.evento._id)

            http.request(app.deleteEvento)
            .then(res => {
                if(res.status == 200){
                    app.deleteEvento.url = '/promoter/event/{event_id}'

                    showSucess(res)
                    this.$emit('close')
                    this.$emit('atualizar')

                }
            })
            .catch()
            .catch((err)=>{
                if (err != 200){    
                    showError(err)
                }
            })
        },
        PutEvent(evento){
            const url = app.putEventos.url
            app.putEventos.url = replaceUrl(url, '{event_id}', this.evento._id)
            app.putEventos.data = this.evento
            http.request(app.putEventos)
             .then(res => {
                if (res.status == 200){
                    app.putEventos.url = '/promoter/event/{event_id}'

                    this.loadingIngressos = false
                    showSucess(res)
                    this.GetTicket()
                }
            })
            .catch(this.loadingIngressos = true)
            .catch((err)=>{
                if (err != 200){    
                    app.putEventos.url = '/promoter/event/{event_id}'

                    this.loadingIngressos = false
                }
                })

        },
        GetTicket(){
            const url = app.getEditTickets.url
            app.getEditTickets.url = replaceUrl(url, '{event_id}', this.evento._id)

            http.request(app.getEditTickets)

             .then(res => {
                app.getEditTickets.url = '/promoter/ticket/{event_id}'

                this.startIngresso = false
                this.ingressos = res.data
            })
            .catch(this.startIngresso = true)
            .catch((err)=>{
                app.getEditTickets.url = '/promoter/ticket/{event_id}'

                if (err != 200){    
                    this.startIngresso = false
                }
                })

        },
        GetProduct(){
            const url = app.getEditProducts.url
            app.getEditProducts.url = replaceUrl(url, '{event_id}', this.evento._id)

            http.request(app.getEditProducts)
             .then(res => {
                app.getEditProducts.url = '/promoter/product/{event_id}'

                this.loadingProduct = false
                this.produtos = res.data        

            })
            .catch(this.loadingProduct = true)
            .catch((err)=>{
                if (err != 200){  
                    app.getEditProducts.url = '/promoter/product/{event_id}'
  
                    this.loadingProduct = false
                }
                })
        },
    },
   
}
</script>
<style scoped>
.card-img{
  margin-top: -50px !important;
  height: 220px;
  margin: auto;
  width: 220px;
  border-radius: 22px;
  border: 2px solid #D356F3;
  color: #D356F3;
}
@media (max-width: 600px){
    .card-img{
        margin-top: -10px !important;

    }
}
.card-img img{
    height: 100%;
    border-radius: 20px;
    
    width: 100%;
}
.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 40px !important;
    height: 40px;
    position: absolute;
    margin-left: -50px;
    cursor: pointer;
}


</style>