<template>
    <div id="Ingresso">
      <v-container v-if="loadingIngresso == false">
          <template>
             <v-form 
              ref="form"
              lazy-validation
              >
            <v-row 
            class="justify-center mx-auto text-center"
            max-width="350">
            <v-col  class="justify-center mx-auto text-center col-12"> 
              <v-text-field 
                    type="text" v-model="ingresso.name"
                    label="Nome lote" outlined max-width="150"
                    required></v-text-field>
            </v-col>
              <v-row class="justify-center mx-auto text-center">
                  <!-- Data ínicio-->
                  <v-col  class=" justify-center mx-auto text-center col-md-6" cols="12">
                    <v-list-item-content>
                    <v-icon>mdi-calendar-check-outline</v-icon>
                    <v-text-field 
                    type="date"  v-model="ingresso.dataStart"
                    label="Data Início" value="dataStart"
                    required></v-text-field>
                    </v-list-item-content>
                  </v-col>
                  <!-- Data fim-->
                  <v-col  class="justify-center mx-auto text-center col-md-6" cols="12">
                    <v-list-item-content>
                      <v-icon>mdi-calendar-check-outline</v-icon>
                      <v-text-field v-model="ingresso.dataEnd"
                      type="date"
                      label="Data Final" 
                      required value="dataEnd"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              <template> 
                <div v-for="(tickes ,idx) in ingresso.ticket" :key="idx">
                  <h5 class="mt-2">Área: {{idx + 1}}</h5>
                  <v-list-item-group class="area-evento">
                    <v-col class="justify-left mx-auto text-center">
                      <!-- Nome Área-->
                      <v-text-field
                      type="text" class="box-text" name="name[]" 
                      label="Nome área" outlined v-model="ingresso.ticket[idx].name"
                      required 
                      ></v-text-field>
                      <v-col class="justify-center mx-auto text-center">
                        <v-row class="mx-auto text-center">
                        <!-- Quantidade do Lote -->
                        <v-list-item-content class=" justify-center mx-auto text-center">
                              <v-icon>mdi-ticket-confirmation</v-icon>
                              <v-text-field 
                              type="number" class="box-input box-2" v-model="ingresso.ticket[idx].qnt"
                              outlined  name="qtn[]" 
                              label="Quantidade"
                              required></v-text-field>
                        </v-list-item-content>
                          <!-- Valor Lote-->
                          <v-list-item-content class=" justify-center mx-auto text-center ml-3">
                            <v-icon>mdi-currency-usd</v-icon>
                            <v-text-field  v-model="ingresso.ticket[idx].price"
                            type="number" class="box-input"
                            outlined  name="price[]" 
                            label="Valor" 
                            required 
                            ></v-text-field>
                          </v-list-item-content>
                         
                          
                            
                        </v-row>
                        <v-row  class=" justify-center">
                          <v-btn dark   
                        style="cursor:pointer" color="error" 
                         @click="DeleteArea(idx)">
                         <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-col>
                    
                </v-list-item-group>
                </div>
              </template>
            </v-row>
            <v-col class="justify-left mx-auto text-center">
             <v-btn class=" mt-5"
                    @click="AddArea()">
                      <v-icon
                        icon:mdi-view-dashboard
                        dark
                        large
                      >mdi-plus</v-icon>
              </v-btn>
            </v-col>
             </v-form>
          </template>
      </v-container>
      <v-container style="height: 400px;" v-if="loadingIngresso == true">
        <v-row 
        class="fill-height"
        align-content="center"
              justify="center"
              >
              <v-col class="justify-center mx-auto text-center"  cols="6">
                <img  class="mb-3" src="@/assets/logo.png">
                <h5> CRIANDO SUA NIGHT...</h5>
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="8"
                ></v-progress-linear>
              </v-col>
            </v-row>
      </v-container>
      <div class="justify-center mx-auto text-center">
         <v-btn
          color="primary" class="mt-5"
          @click="$emit('next')"
          > <!-- e6 = 4 $emit('next') -->
          Próximo
        </v-btn>
        <v-btn text
          @click="$emit('close'), $emit('atualizar')" class="mt-5">
          Cancel
        </v-btn>
        <v-btn
        color="dark" class="mt-5"
        @click="Enviar()"> <!-- e6 = 4 -->
          Adicionar
        </v-btn>
      </div>
    </div>
</template>
<script>
import { showError, showSucess} from '@/global'
import { app, http } from '@/server'

export default {
  props: ['IdEvento'],
  computed: {
    getEvento:{
      get(){
      return this.IdEvento
      },

    }
  },
  data(){
    return{
      date:'',
      loadingIngresso: false,
      e6:1,
      ingresso:{
        eventId: null,
        dataStart:null,
        dataEnd:null,
        name: null,
        description: null,
        ticket:[{ name:'', price:'', qnt:''}]
      },
    }
  },
  methods:{
    Enviar(){
      this.ingresso.eventId = this.IdEvento 
      app.createTicket.data = this.ingresso
      http.request(app.createTicket)
      .then((res) => {
        if (res.status == 200){
          this.loadingIngresso = false
          showSucess(res);
          this.Restart()
        }
      })
      .catch(this.loadingIngresso = true)
      .catch((err)=>{
        console.log(this.ingresso)

        if (err != 200){    
          this.loadingIngresso = false
          this.Restart()
          showError(err)
        }
     })
    },
    AddArea(){
      this.ingresso.ticket.push({name:'', price:'', qtn:''})
    },
    DeleteArea(idx){
      if(idx > 0)this.ingresso.ticket.splice(idx,1)
    },
    Restart(){
      this.ingresso.dataStart = null
      this.ingresso.dataEnd = null
      this.ingresso.name = null
      this.ingresso.ticket=[{ name:'', price:'', qtn:''}]
      this.loadingIngresso = false

    }
  },
}
</script>
<style scoped>
.box-input{
  max-width: 160px !important;
  min-width: 90px !important;
}

.box-text{
  width: 200px !important;
  margin-top: -35px!important;
  margin-left: -15px !important;
  margin-bottom: -20px !important;
  background-color: #120222 ;
}
.area-evento{
  border: 1px dotted;
  margin-top: 30px ;
}
</style>