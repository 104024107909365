<template>
    <v-card>
        <v-subheader v-if="loadingBebida == false">Bebidas || EVENTO: {{evento.name}}</v-subheader>
        <v-subheader v-if="loadingBebida == false">RC: {{evento.rc}}</v-subheader>

        <v-card-text v-if="loadingBebida == false">
            <v-flex   v-for="item in bebidas" class="justify-center mx-auto mt-2" :key="item._id">
                <v-card class="cards-padrao">
                   <v-divider></v-divider>

                    <v-col md="10" cols="10" class=" justify-center  text-center mx-auto">
                        <v-text-field 
                        type="text" label="Nome:" outlined
                        v-model="item.name" required>
                        </v-text-field>
                    </v-col>
                        
                    <v-col class="card-text">
                        <v-row class="justify-center mx-auto text-center">
                            <div class="card-img">
                                <img :src="`${item.photo.location}`">
                            </div>
                               
                               
                            <v-col>
                                <v-text-field 
                                type="number" label="Preço:"
                                v-model="item.price" required>
                                </v-text-field>
                           </v-col>
                           <v-col>
                                <v-text-field 
                                type="number" label="Quantidade:"
                                v-model="item.qnt" required>
                                </v-text-field>
                            </v-col>
                            </v-row>
                            <v-col class="mx-auto text-center"> 
                                <v-btn class=" mt-2 mr-2" @click="DeletDrink(item)" color="error">EXCLUIR</v-btn>
                                <v-btn color="primary" @click="PutDrink(item)" class="mt-2">SALVAR</v-btn>
                            </v-col>
                        </v-col>
                </v-card>
            </v-flex>
                
                <!-- CRIAÇÂO DE BEBIDA-->
                <v-flex    class="justify-center mx-auto mt-2"  >
                    <v-card class="cards-padrao" style="background: #c6c6c6">
                        <v-divider></v-divider>
                            
                        <v-col md="10" cols="10" class=" justify-center  text-center mx-auto">
                            <h2 class="mt-2 mb-5"> CRIAR BEBIDA </h2> 
                            <v-divider></v-divider>
                            <v-text-field 
                            type="text" label="Nome:" outlined
                            v-model="Bebida.name" required>
                            </v-text-field>
                        </v-col>
                        
                        
                        <v-col class="card-text">
                            <v-row class="justify-center mx-auto text-center">
                                <v-col cols="12" class="col-md-3">
                               <div class="card-img">
                                    <div div class="imagem2-upload">
                                        <div class="justify-center mx-auto text-center">
                                            <input type="file" 
                                            @change="pickFile1"
                                            name="file1"
                                            ref="fileInput"
                                            class="input-file">
                                            <button>
                                            <!-- icone de erro-->
                                            <v-icon
                                            dense color="red" @click="selectImage1"
                                            v-if="loading1 == false & previewImage == null & error == true"
                                            >mdi-cloud-alert</v-icon>
                                            <!-- icone de nuvem-->
                                            <v-icon
                                            @click="selectImage1" color="#000" 
                                            v-if="previewImage == null & loading1 == false & error == false" 
                                            >mdi-cloud-upload</v-icon>
                                            <!-- carregando-->
                                            <v-progress-circular
                                            indeterminate
                                            color="purple"
                                            v-if=" loading1 == true & previewImage == null"
                                            @click="selectImage1"
                                            ></v-progress-circular>
                                            </button>
                                        </div>
                                        <div
                                        class=" justify-center imagePreviewWrapper" 
                                        :style="{ 'background-image': `url(${previewImage})` }">
                                        </div>
                                    </div>
                               </div>
                                </v-col>
                               
                            <v-col cols="6" class="col-md-3">
                                <v-text-field 
                                type="number" label="Preço:"
                                v-model="Bebida.price" required>
                                </v-text-field>
                           </v-col>
                           <v-col cols="6" class="col-md-3">
                                <v-text-field 
                                type="number" label="Quantidade:"
                                v-model="Bebida.qnt" required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="8" class="col-md-3">
                                <v-select
                                class="box-input"  
                                label="Tipo" :items="tipos"
                                v-model="Bebida.type" 
                                required>
                                </v-select>
                            </v-col>
                            </v-row>
                            <v-col class="mx-auto text-center"> 
                                <v-btn color="primary" @click="CreateDrinks()" class="mt-2">SALVAR</v-btn>
                            </v-col>
                        </v-col>
                    </v-card>
                </v-flex>
            </v-card-text>
            <v-card-text>
                 <v-container style="height: 400px;" v-if="loadingBebida == true">
                    <v-row 
                    class="fill-height"
                    align-content="center"
                    justify="center"
                        >
              <v-col class="justify-center mx-auto text-center"  cols="6">
                <img  class="mb-3" src="@/assets/logo.png">
                <h5> CARREGANDO...</h5>
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="8"
                ></v-progress-linear>
              </v-col>
            </v-row>
            </v-container>
            </v-card-text>
    </v-card>  
</template>
<script>
import {showError, showSucess} from '@/global'
import { app, http } from '@/server'
import moment from 'moment';
import service from "@/config/services"
import { replaceUrl } from '@/functions/helpers'

export default{
    data(){
        return{
            tab: 0,
            photo1:null,
            tipos:['DRINK', 'FOOD'],
            previewImage: null,
            loading1: false,
            error: false,
            bebida:{},
            id: null,
            Bebida:{
                localId:this.evento._id,
                name: null,
                price: null,
                qnt: null,
                type: {
                enum: "DRINK"
                },
                photo: {
                location: null,
                key: null,
                },
                description: null
            },
        }
    },
    props: {
    visible: Boolean,
    evento: Object,
    loadingBebida: Boolean,
    bebidas: Array
    },
    computed: {
        getEvento:{
            get(){
                return this.evento, this.bebidas, this.loadingBebida

            }
        },
        show: {
            get () {
                this.GetTicket()
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        }
    },
    filters: {
        formatDate:function(value){
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        }
    },
    methods:{
        selectImage1 () {
        this.$refs.fileInput.click()
        },
        pickFile1() {
            this.photo1 = this.$refs.fileInput.files[0];
            const formData = new FormData();
            formData.append('file', this.photo1);
            service.uploadImage.data = formData
            http.request(service.uploadImage, formData).then((res) => { 
                this.Bebida.photo.key = res.data.key
                this.Bebida.photo.location =res.data.location
                console.log(this.Bebida)
                let input = this.$refs.fileInput
                let file = input.files
                if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
                this.$emit('input', file[0])
                }
            }) .catch(this.loading1 = true, this.previewImage = null, this.error = false)
            .catch((err)=>{
                if (err != 200){    
                this.loading1 = false
                this.error = true
                showError(err)
                }
            })
        },
        GetDrinks(){
            const url = app.getEditProducts.url
            app.getEditProducts.url = replaceUrl(url, '{event_id}', this.evento._id)

            http.request(app.getEditProducts)
             .then(res => {
                app.getEditProducts.url = '/promoter/product/{event_id}'

                this.loadingBebida = false
                this.bebidas = res.data
            })
            .catch(this.loadingBebida = true)
            .catch((err)=>{
                if (err != 200){ 
                    app.getEditProducts.url = '/promoter/product/{event_id}'
   
                    this.loadingBebida = false
                }
                })

         },
        PutDrink(bebida){
            this.bebida = { ...bebida}
            this.id= this.bebida._id
            delete this.bebida._id 
            delete this.bebida.localId
            delete this.bebida.registerDate
            delete this.bebida.__v
            const url = app.putEditProducts.url
            app.putEditProducts.url = replaceUrl(url, '{product_id}', this.id)
            app.putEditProducts.data = this.bebida
            http.request(app.putEditProducts)
            .then(res => {
                if (res.status == 200){
                    app.putEditProducts.url = '/promoter/product/{product_id}'
                    this.loadingIngressos = false
                    showSucess(res)
                    this.GetDrinks()
                }
            })
            .catch(this.loadingIngressos = true)
            .catch((err)=>{
                if (err != 200){    
                    this.loadingIngressos = false
                }
            })
            

        },
        DeletDrink(bebida){
            this.bebida = { ...bebida}
            const url = app.deleteProducts.url
            app.deleteProducts.url = replaceUrl(url, '{product_id}', this.bebida._id)
            http.request(app.deleteProducts)
            .then(res => {
                if (res.status == 200){
                    app.deleteProducts.url = '/promoter/product/{product_id}'

                    this.loadingBebida  = false
                    this.GetDrinks()
                }
            })
            .catch(this.loadingBebida  = true)
            .catch((err)=>{
                if (err != 200){   
                    app.deleteProducts.url = '/promoter/product/{product_id}'
 
                    this.loadingBebida  = false
                }
                })

        },
        CreateDrinks(){
            app.createBebida.data = this.Bebida
            http.request(app.createBebida)
            .then(res =>{
                if(res.status == 200){
                        this.loadingBebida = false
                        showSucess(res)
                        this.GetDrinks()
                        this.ResetDrinks()
                 }
            })
            .catch(this.loadingBebida = true)
            .catch((err)=>{
                if (err != 200){    
                this.loadingBebida = false
                showError(err)
                }
            })
        },
        ResetDrinks(){
          this.loadingBebida = false
          this.previewImage = null
          this.error = false
          this.loading1 = false
          this.Bebida.name = null
          this.Bebida.qnt = null
          this.Bebida.price = null
          this.Bebida.type = null

        }
    }
}
</script>
<style scoped>
.card-img{
  height: 100px;
  margin: auto;
  width: 100px;
  border-radius: 22px;
  border: 2px solid #D356F3;
  color: #D356F3;
}
.card-img img{
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.imagem2-upload{
  border-radius: 22px;
  width: 100px;
  height: 100px;
  line-height: 90px;
  margin-left: -5px !important; 
  cursor: pointer;
  
}
.imagePreviewWrapper {
   border-radius: 20px;
   line-height: 80px;
    width: 95%;
    height: 95%;
    display: block;
    margin-top: -95px;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    
}
.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 40px !important;
    height: 40px;
    position: absolute;
    margin-left: -50px;
    cursor: pointer;
}
</style>